import { Link } from 'gatsby';
import cn from 'classnames';
import React from 'react';
import {
  connectStateResults,
  Highlight,
  Hits,
  Snippet,
  PoweredBy
} from 'react-instantsearch-dom';
import SearchLoader from './search-loader';

const LoadingIndicator = connectStateResults(({ searching }) => {
  return searching ? <SearchLoader /> : null;
});

const NoResults = connectStateResults(
  ({ searchResults, searchState, searching }) => {
    return (
      !searching &&
      searchResults?.nbHits === 0 && (
        <div>
          No results have been found for{' '}
          <span className='text-purple-400'>{searchState.query}</span>.
        </div>
      )
    );
  }
);

//  iterates over all indices and displays hits for each of them using the HitsInIndex component
const SearchResult = ({ show = false, className }) => {
  // connectStateResults wraps components to provide them with details about the current search such
  // as the query, the number of results and timing statistics.
  const HitCount = connectStateResults(({ searchResults }) => {
    const hitCount = searchResults && searchResults.nbHits;
    return hitCount > 0 ? (
      <div className='HitCount'>
        {hitCount} result{hitCount !== 1 ? `s` : ``}
      </div>
    ) : null;
  });

  // responsible for displaying a single page (“hit”) in a search result.
  const PageHit = ({ hit }) => {
    return (
      <div className='pb-4 border-b border-gray-600'>
        <Link to={hit.pathName}>
          <h4>
            <Highlight
              className='text-purple-400 font-bold hover:border-b-2 hover:border-blue-500'
              attribute='title'
              hit={hit}
              tagName='mark'
            />
          </h4>
        </Link>
        <Snippet attribute='excerpt' hit={hit} tagName='mark' />
      </div>
    );
  };

  const Results = connectStateResults(
    ({ searchResults, isSearchStalled }) =>
      searchResults &&
      searchResults.nbHits !== 0 &&
      !isSearchStalled && (
        <>
          <HitCount />
          <Hits className='Hits' hitComponent={PageHit} />
        </>
      )
  );

  return (
    <div
      className={cn(
        className,
        'search-result bg-gray-800 text-white absolute',
        show ? 'block' : 'hidden'
      )}
    >
      <Results />
      <LoadingIndicator />
      <NoResults />
      <PoweredBy className='text-white text-xs mt-1' />
    </div>
  );
};

export default SearchResult;
