import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Clipboard from '../../../static/svg/clipboard.svg';
import Tick from '../../../static/svg/tick.svg';

export default ({ className = '', text }) => {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisibility(false);
    }, 4000);
  }, [visibility]);

  const onClick = () => {
    const textField = document.createElement('textarea');
    textField.textContent = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setVisibility(true);
  };
  return (
    <>
      {visibility ? (
        <div className={cn(className)}>
          <div className='relative'>
            <span className='text-green-300'>
              <Tick className='w-4 h-4' />
            </span>
            <div className='absolute top-0 bg-gray-900 rounded text-white w-14 mt-5 -ml-6 p-1/2 text-center text-xs'>
              Copied
            </div>
          </div>
        </div>
      ) : (
        <Clipboard
          onClick={onClick}
          className={`${className} w-4 h-4 cursor-pointer`}
        />
      )}
    </>
  );
};
