import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import Logo from '../../../static/svg/logo.svg';
import Search from '../search';
const searchIndices = [
  {
    name: process.env.GATSBY_ALGOLIA_INDEX_NAME,
    title: process.env.GATSBY_ALGOLIA_INDEX_NAME
  }
];

export default () => {
  const SearchBlock = ({ className }) => (
    <div className={cn('flex flex-grow max-w-4xl mx-3', className)}>
      <Search indices={searchIndices} />
    </div>
  );
  return (
    <header className='fixed top-0 h-32 lg:h-16 z-50 w-full bg-gray-900 border-b border-gray-800 shadow-md text-white'>
      <div className='flex items-center h-16 flex-row relative'>
        <Link to='/' className='max-w-xxs lg:w-full flex-none ml-16 lg:ml-6'>
          <Logo className='w-24' />
        </Link>
        <SearchBlock className='hidden lg:block' />
        <div className='flex flex-end mr-6 ml-auto max-w-xl'>
          <Link to='/' className='mr-4 w-auto flex-none'>
            Docs
          </Link>
          <Link to='/api' className='w-auto flex-none'>
            API Reference
          </Link>
        </div>
      </div>
      <SearchBlock className='lg:hidden' />
    </header>
  );
};
