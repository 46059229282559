import React from 'react';

const SearchLoader = () => {
  const Block = () => (
    <div className='p-2 flex flex-row mb-3 last:mb-0'>
      <div className='w-full'>
        <div className='loader-box w-2/5 h-3 rounded-md'></div>
        <div className='loader-box h-3 rounded-md w-full mt-2'></div>
        <div className='loader-box h-3 rounded-md w-full mt-1'></div>
      </div>
    </div>
  );

  const rows = [];
  for (let i = 0; i <= 2; i++) {
    rows.push(<Block key={i} />);
  }

  return <>{rows}</>;
};

export default SearchLoader;
