import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../templates/shared/layout';

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <div className='min-h-full mt-24 text-center'>
        <h1 className='mt-4'>Page not found</h1>
        <p className='mt-4'>
          <Link to='/'>Return home</Link>
        </p>
      </div>
    </Layout>
  );
};
