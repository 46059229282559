import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Code from '../../components/code';
import favicon from '../../../static/favicon.png';
import '../../styles.css';
import '../../markdown.css';

const components = {
  a: ({ children, href, ...props }) => {
    if (href.startsWith('http')) {
      return (
        <a href={href} target='_blank' rel='noopener noreferrer' {...props}>
          {children}
        </a>
      );
    }

    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  },
  img: ({ src, ...props }) => {
    const prefixedSrc = withPrefix(src);
    return <img src={prefixedSrc} {...props} />;
  },
  pre: ({ children }) => {
    return <Code {...children.props} />;
  }
};

export default props => {
  return (
    <>
      <Helmet>
        <link href='/styles/prism.css' type='text/css' />
        <link rel='icon' type='image/png' href={favicon} />
      </Helmet>
      <div className='min-h-screen min-w-screen text-gray-900 flex flex-col'>
        <MDXProvider components={components}>
          <Header />
          <div className='flex-grow pt-4'>{props.children}</div>
          <Footer />
        </MDXProvider>
      </div>
    </>
  );
};
