import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import Copy from '../copy';
import theme from './theme';

export default ({ children, className = '', showCopy = true }) => {
  const codeLang = className.replace('language-', '');
  return (
    <Highlight
      {...defaultProps}
      code={children}
      language={codeLang}
      theme={theme}
    >
      {({ className, tokens, getLineProps, getTokenProps }) => (
        <pre className={`relative p-4 ${className}`}>
          {showCopy && (
            <Copy
              className='absolute right-0 top-0 mt-4 mr-4'
              text={children}
            />
          )}
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
