import React, { useState } from 'react';
import { debounce } from 'lodash';
import { connectSearchBox } from 'react-instantsearch-dom';

export default connectSearchBox(
  ({ refine, className, onFocus, currentRefinement, delay }) => {
    const [value, setValue] = useState(currentRefinement);

    const debouncedSearch = debounce(e => refine(e.target.value), delay);

    const onChange = e => {
      e.persist();
      const value = e.currentTarget.value;
      setValue(value);
      debouncedSearch(e, e.eventTarget);
    };

    return (
      <form className={className}>
        <input
          className='w-full transition-colors duration-100 ease-in-out text-gray-400 bg-gray-800 focus:bg-gray-700 shadow-md outline-none border border-transparent placeholder-gray-600 rounded-md py-2 pr-4 pl-3'
          type='text'
          placeholder='Search'
          aria-label='Search'
          onChange={onChange}
          value={value}
          onFocus={onFocus}
        />
      </form>
    );
  }
);
