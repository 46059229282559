import React from 'react';

export default () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='mt-4 w-full border-t border-gray-200 shadow-md bg-white z-50'>
      <div className='text-xs flex items-center justify-center h-16 flex-row'>
        &copy; {currentYear} Solve Data Ltd.
      </div>
    </footer>
  );
};
