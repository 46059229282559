import algoliasearch from 'algoliasearch/lite';
import React, { createRef, useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import SearchBox from './search-box';
import SearchResult from './search-result';
import { useClickOutside } from '../hooks/mouse';
import { useKeyDown } from '../hooks/keyboard';

export default function Search({ indices }) {
  const rootRef = createRef();
  const [query, setQuery] = useState();
  const [hasFocus, setFocus] = useState(false);
  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  const onClose = () => setFocus(false);

  useKeyDown(27, onClose);
  useClickOutside(rootRef, onClose);

  const searchClient = {
    search(requests) {
      const shouldSearch = requests.some(
        ({ params: { query } }) => query !== ''
      );
      if (shouldSearch) {
        return algoliaClient.search(requests);
      }
      return Promise.resolve({
        results: [{ hits: [] }]
      });
    },
    searchForFacetValues: algoliaClient.searchForFacetValues
  };

  return (
    <div className='w-full relative' ref={rootRef}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <SearchBox
          onFocus={() => setFocus(true)}
          hasFocus={hasFocus}
          delay={1000}
        />

        <SearchResult show={query && query.length > 0 && hasFocus} />
      </InstantSearch>
    </div>
  );
}
