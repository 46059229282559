import React from 'react';
import Highlight from './highlight';
// import GraphQL from './graphql';

// NOTE - this was written to allow GraphQL queries that are in our docs to hit a custom endpoint
// and display the results in the code viewer itself. We've disabled it for the moment
// as other things have higher priority. Main work that still needs to be done here is
// storing the API key and URL details somewhere for the current user and using those
// to hit their own API. It's currently hard coded to localdev.

// Code has simply been commented out for future use

export default ({ children, className = '' }) => {
  // const codeLang = className.replace('language-', '');

  // if (codeLang.toLowerCase() !== 'graphql') {
  return (
    <div className='my-4 text-blue-500 border border-gray-800 bg-gray-800 rounded-md overflow-scroll'>
      <Highlight className={className}>{children}</Highlight>
    </div>
  );
  // }

  // return (
  //   <div className='my-4 text-purple-500 border border-gray-800 bg-gray-800 rounded-md overflow-scroll'>
  //     <GraphQL className={className}>{children}</GraphQL>
  //   </div>
  // );
};
